import { BaseElement, html, css } from 'Components';
import { getFormControls } from '@shoelace-style/shoelace/dist/utilities/form.js';
import { Fetcher, Notify } from 'Utils';
import localStyles from './styles.js';

class Form extends BaseElement {
  static get styles() {
    return [
      //super.styles,
      localStyles,
      css`

        :host {
          display:none;
        }

        form {
          border-left:1px solid var(--sl-color-gray-200);
          padding-right:20px;
          padding-left:20px;
          flex:1;
          color:var(--text-color);
        }

        h2 {
          margin-top:5px;
        }
        
        .h2_switch {
          display:flex;
          justify-content:space-between;
          width:100%;
        }

        .h2_switch span {
          width:200px;
        }

        .buttons {
          margin-top:40px;
          text-align:right;
        }

        m-icon {
          float:left;
          margin-right:7px;
        }

        h2 sl-button {
          float:right;
        }

        sl-switch {
          color:gray;
          text-transform:none;
          text-align:left;
          width:120px;
          margin-right:20px;
        }

        sl-switch[checked] {
          color:var(--sl-color-primary-500);
        }

        m-icon[name="cached"] {
          color:var(--sl-color-gray-400);
        }

        m-icon[name="cached"]:hover {
          color:var(--sl-color-primary-500);
        }

        sl-textarea::part(textarea) {
          font-size:11px;
          font-family:Monospace;
          white-space:nowrap;
          field-sizing: content;
          overflow-x:scroll;
          max-width:600px;
          min-height:100px;
          padding-left:5px;
        }

        sl-input::part(input) {
          font-size:11px;
          font-family:Monospace;
          white-space:nowrap;
        }

        .settings {
          max-height:1200px;
          transition:max-height 0.2s;
          overflow:hidden;
          padding-left:20px;
          padding-right:40px;
        }

        a {
          font-size:14px;
          color:var(--sl-color-primary-500);
        }

        .warning {
          background-color:var(--sl-color-warning-100);
          padding:10px;
          margin:10px;
          margin-top:0px;
          font-size:0.9em;
          line-height:20px;
        }

        .warning m-icon {
          font-size:40px;
          float:left;
        }

      `
    ];
  }

  static get properties() {
    return {
      settings: { type: Object },
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title: 'Request Tracker Notifications',
            button_save: 'Save',
            refresh:'Refresh',
            errors:{
              EEMPTY_LASTNAME: 'The lastname is mandatory',
              EEMPTY_FIRSTNAME: 'The firstname is mandatory',
            },
            message_success:'Changes has been saved.'
          }
        },
        french:{
          translation: {
            title: 'Notifications Request Tracker',
            button_save: 'Enregistrer',
            refresh:'Rafraîchir',
            errors:{
              EEMPTY_LASTNAME: 'Le nom est obligatoire',
              EEMPTY_FIRSTNAME: 'Le prénom est obligatoire',
            },
            message_success:'Les modifications ont été enregistrées.'
          }
        }
      }
    ]
  }
  
  constructor() {
    super();
    this.settings = null;
    this.url = 'private/admin/rt/settings';
  }

  async firstUpdated() {
    super.firstUpdated();
    await this.refresh();
  }

  async refresh(ev) {
    let target;
    if (ev) {
      target = ev.target.tagName === 'M-ICON' ? target = ev.target.parentElement : target = ev.target;
      target.loading = true;
    }
    const response = await Fetcher.get(this.url);
    if (response) {
      this.settings = response.data.data || {};
    }

    if (ev) {
      setTimeout(() => {
        if (target) target.loading = false;
      }, 1000);
    }

    /*
    setTimeout(async () => {
      const textareas = this.shadowRoot.querySelectorAll('sl-textarea');
      for (let i = 0, len = textareas.length; i < len; ++i) {
        try {
          textareas[i].resize = 'auto';
          await Sleep(1000);
        } catch(e) {

        }
      }
    }, 100);
    */
  }

  toggleForm(form, readonly) {
    const elements = getFormControls(form);
    for (let i = 0, len = elements.length; i < len; ++i) {
      //elements[i].readonly = readonly;
      elements[i].disabled = readonly;
    }
  }

  getFormValues(form) {
    const elements = getFormControls(form);
    const data = {};
    for (let i = 0, len = elements.length; i < len; ++i) {
      const element = elements[i];
      const tagName = element.tagName.toLowerCase();
      if (tagName === 'sl-button') continue;
      
      if (tagName === 'sl-switch') {
        // Convertit la valeur checked en 1 ou 0
        data[element.name] = element.checked ? 1 : 0;
      } else {
        // Utilise la valeur de l'élément directement pour les autres types
        data[element.name] = element.value;
      }
    }
    return data
  }

  async handleSubmitInfos(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    const form = this.shadowRoot.querySelector('form');
    this.toggleForm(form, true);

    const bt = this.shadowRoot.querySelector('#bt_submit_info');
    bt.disabled = true;
    bt.loading = true;

    const data = this.getFormValues(form);

    const response = await Fetcher.post(this.url+'/update', data);

    bt.loading = false;
    bt.disabled = false;
    this.toggleForm(form, false);

    if (!response) {
      return;
    }

    if (!response.ok) {
      Notify.error('Erreur inconnue');
      return;
    }

    Notify.success({ message:this._tl('message_success') });

  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  async handleTest(el, what) {
    el.loading = true;
    const response = await Fetcher.post(this.url+'/test/'+what);
    el.loading = false;
    if (!response.ok) {
      Notify.error('Une erreur est survenue:<br/>'+response.error);
    } else {
      Notify.success({ message:'Notification envoyée' });
    }
  }

  async handleTestSms(ev) {
    await this.handleTest(ev.target, 'rtTestSMS');
  }

  async handleTestNtfy(ev) {
    await this.handleTest(ev.target, 'rtTestNTFY');
  }

  async handleTestTeams(ev) {
    await this.handleTest(ev.target, 'rtTestTeams');
  }

  handleSwitchChange(ev) {
    this.settings[ev.target.name] = ev.target.checked ? 1 : 0;
    this.requestUpdate();
  }

  handleJsonInput(ev) {
    clearTimeout(this.jsonCheckTimeout);
    const textel = ev.target;
    this.jsonCheckTimeout = setTimeout(() => {
      try {
        JSON.parse(textel.value);
      } catch(e) {
        Notify.error('Payload JSON invalide<br/>'+e.message);
      }
    }, 100);
  }

  render() {
    if (!this.settings) return;

    return html`
      ${ENV === 'preprod' ? html`
        <div class="warning">
          <m-icon name="warning" nogradient></m-icon>
          <b>Avertissement environnement de pré-production</b> :<br/>Toutes modifications de paramétrage de cette section n'a <b>pour le moment</b> aucun effet.
        </div>
      ` : ''}
      <form @submit="${this.handleSubmitInfos}">
        <h2>
          <m-icon name="notifications_active"></m-icon>
          ${this._tl('title')}
          <sl-button variant="text" size="small" title="${this._tl('refresh')}" @click="${this.refresh}"><m-icon name="cached" nogradient></sl-button>
        </h2>
        La plateforme vérifie l'existance de nouveaux tickets toutes les 5 minutes. Si un nouveau ticket est détecté, une notification est envoyée.
        <br/><br/>
        <b>Passerelles de notification</b>
        <br/><br/>

        <h2 class="h2_switch">
          <span>SMS</span>
          <sl-switch 
            name="mobileNotifySms" 
            @sl-change="${this.handleSwitchChange}" 
            ?checked="${this.settings.mobileNotifySms}">${this.settings.mobileNotifySms ? 'Activé' : 'Désactivé'}
          </sl-switch>
        </h2>
        <div class="settings" style="max-height:${this.settings.mobileNotifySms ? '': '0px'}">
          <sl-textarea 
            name="mobileNumbers"
            label="Numéros de mobile"
            spellcheck="false"
            help-text="Un numéro par ligne au format international (+330xxxxxxxxx), commentaires avec # n'importe ou"
            .value="${this.settings.mobileNumbers || ''}"
          ></sl-textarea>
          <br/>
          <sl-input 
            name="templateSMS"
            label="Message"
            spellcheck="false"
            help-text="Défault: {{ticketsCount}} à prendre en charge sur Request Tracker".
            .value="${this.settings.templateSMS || ''}"
          ></sl-input>
          <br/>
        </div>

        <br/>
        <h2 class="h2_switch">
          <span>NTFY</span>
          <sl-switch 
            name="mobileNotifyNtfy" 
            @sl-change="${this.handleSwitchChange}" 
            ?checked="${this.settings.mobileNotifyNtfy}">${this.settings.mobileNotifyNtfy ? 'Activé' : 'Désactivé'}
          </sl-switch>
        </h2>
        <div class="settings" style="max-height:${this.settings.mobileNotifyNtfy ? '': '0px'}">
          <sl-input 
            name="ntfyEndpoint"
            label="URL de la passerelle NTFY"
            spellcheck="false"
            help-text="Par défault : https://helix01fr.sysdream.io/ntfy/. L'URL DOIT se terminer par /".
            .value="${this.settings.ntfyEndpoint || ''}"
          ></sl-input>
          <br/>
          <sl-textarea
            name="templateNTFY"
            label="Message"
            @input="${this.handleJsonInput}"
            spellcheck="false"
            help-text="Attention, l'attribut topic EST le canal de notification."
            .value="${this.settings.templateNTFY || ''}"
          ></sl-textarea>
          <a href="https://docs.ntfy.sh/publish/#using-a-json-array" target="_blank">Documentation NTFY</a>
          <br/>
        </div>

        <br/>
        <h2 class="h2_switch">
          <span>Teams</span>
          <sl-switch 
            name="teamsNotify" 
            @sl-change="${this.handleSwitchChange}" 
            ?checked="${this.settings.teamsNotify}">${this.settings.teamsNotify ? 'Activé' : 'Désactivé'}
          </sl-switch>
        </h2>
        <div class="settings" style="max-height:${this.settings.teamsNotify ? '': '0px'}">
          <sl-textarea
            name="teamsWebhooks"
            label="Webhook Teams"
            spellcheck="false"
            help-text="Une URL par ligne, commentaire avec #"
            .value="${this.settings.teamsWebhooks || ''}"
          ></sl-textarea>
          <br/>
          <sl-textarea
            name="templateTeams"
            label="Message"
            spellcheck="false"
            @input="${this.handleJsonInput}"
            help-text=""
            .value="${this.settings.templateTeams || ''}"
          ></sl-textarea>
          <a href="https://adaptivecards.io/designer/" target="_blank">Documentation Microsoft Tteams</a>
        </div>

        <div class="buttons">
          <sl-button @click="${this.handleSubmitInfos}" id="bt_submit_info" variant="primary">${this._tl('button_save')}</sl-button>
        </div>

        <br/><br/>
        <br/>
        <b>Test de la configuration</b><br/><br/>
        Ces boutons permettent d'actionner le mécanisme de notification avec la configuration actuelle, y compris les templates.
        <br/><br/>
        <sl-button @click="${this.handleTestSms}" variant="default" ?disabled="${!this.settings.mobileNotifySms}">Notification SMS</sl-button>
        <sl-button @click="${this.handleTestNtfy}" variant="default" ?disabled="${!this.settings.mobileNotifyNtfy}">Notification NTFY</sl-button>
        <sl-button @click="${this.handleTestTeams}" variant="default" ?disabled="${!this.settings.teamsNotify}">Notification Teams</sl-button>
      </form>
    `
  }

}

customElements.define('rt-notification', Form);