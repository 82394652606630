import { PageElement, html, css } from 'Components';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        iframe {
          height: 100%;
          width: 100%;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  onClick(ev) {
    const frameUrl = ev.target.getAttribute('frame');
    const modal = this.qs('modal-drawer');
    modal.label = ev.target.innerText;
    modal.show();
    
    const iframe = this.qs('iframe');
    iframe.src = frameUrl;
  }

  render() {
    return html`
      <section-header backroute="/private/doc" micon="api">API</section-header>
      <br/><br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="api" frame="/api/v2/public/api-docs/" @click=${this.onClick}>API sans authentification</box-styled>
        <box-styled layout="${this.layout}" micon="api" frame="/api/v2/private/api-docs/" @click=${this.onClick}>API avec authentification</box-styled>
        <box-styled layout="${this.layout}" micon="api" frame="/api/v2/__webhooks/api-docs/"  @click=${this.onClick}>Webhooks internes</box-styled>
      </div>

      <modal-drawer>
        <iframe src=""></iframe>
      </modal-drawer>
    `;
  }

}

customElements.define('page-doc-api', Page);