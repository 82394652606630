import { PageElement, html, css } from 'Components';

import './entities';
import './rt';
import './conference';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }
  
  render() {
    return html`
      <section-header size="big" backroute="../" micon="developer_board">Espace développeur</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="developer_board" aroute="/public/tests">Tests UX</box-styled>
      </div>
      <br/>
      <section-header size="medium" micon="format_overline">Bac a sable</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="prescriptions" aroute="./entities">Entités & attributs</box-styled>
        <box-styled layout="${this.layout}" micon="pest_control" aroute="./rt">Request Tracker</box-styled>
        <box-styled layout="${this.layout}" micon="person_play" aroute="./conference">Conférence</box-styled>
      </div>
      <br/>
      <br/>
    `;
  }

}

customElements.define('page-dev-home', Page);