import { PageElement, html, css } from 'Components';
import { Session } from 'Utils';

import './api';
import './faq';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }

  renderPrivateDocumentation() {

    if (!Session.isBoss()) return;

    return html`
      <section-header size="medium" micon="admin_panel_settings">Accès restreint</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="api" aroute="/private/doc/api">API</box-styled>
        <box-styled layout="${this.layout}" micon="chat_error" aroute="/public/errors">Messages d'erreur</box-styled>
      </div>
      <br/>
    `
  }

  renderPublicDocumentation() {
    return html`
      <section-header size="medium" micon="description">accès publique</section-header>
      <br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="help" aroute="/private/doc/faq">FAQ</box-styled>
      </div>
      <br/>
    `
  }

  render() {
    return html`
      <section-header backroute="/private" micon="quiz">Documentations</section-header>
      <br/><br/>
      ${this.renderPublicDocumentation()}
      ${this.renderPrivateDocumentation()}
      <br/><br/>
    `;
  }

}

customElements.define('page-doc', Page);